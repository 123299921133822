<div
  class="loader-mask"
  *ngIf="isLoading"
>
  <adxad-loader [diameter]="40"></adxad-loader>
</div>

<adxad-sidebar-modal-container
  class="profile-view"
  *ngIf="profile && !isLoading"
>
  <adxad-sidebar-modal-header>
    <adxad-sidebar-modal-close-btn (click)="closeModal()"></adxad-sidebar-modal-close-btn>
    <h1 adxadSidebarModalTitle>{{ profile.email }}</h1>
  </adxad-sidebar-modal-header>

  <adxad-sidebar-modal-body>
    <section
      adxadSidebarModalSection
      *ngIf="roles.isPublisher()"
    >
      <div class="row">
        <adxad-sidebar-modal-info-block
          class="profile-view__balance"
          label="{{ 'label_yourBalance' | transloco }}"
          [class]="profile.blockedBalance > 0 ? 'col-6' : 'col-12'"
        >
          {{ profile.balance | currency }}

          <button
            adxadStrokedButton
            class="profile-view__balance-btn"
            [disabled]="profile.balance < 50"
            (click)="orderPayout()"
          >
            {{ 'action_orderPayout' | transloco }}
          </button>
        </adxad-sidebar-modal-info-block>
        <adxad-sidebar-modal-info-block
          class="profile-view__balance col-6"
          label="{{ 'blockedBalance' | transloco }}"
          *ngIf="profile.blockedBalance > 0"
        >
          {{ profile.blockedBalance | currency }}
        </adxad-sidebar-modal-info-block>
      </div>
    </section>

    <section adxadSidebarModalSection>
      <h4
        class="profile-view__section-title"
        (click)="isOpenSection.account = !isOpenSection.account"
      >
        {{ 'accountDetails' | transloco }}

        <span
          class="material-icons profile-view__section-title-icon"
          [class.open]="isOpenSection.account"
        >
          keyboard_arrow_down
        </span>
      </h4>

      <div
        class="row"
        *ngIf="isOpenSection.account"
        @slideToggle
      >
        <adxad-sidebar-modal-info-block
          class="col-12"
          label="{{ 'status' | transloco }}"
        >
          <adxad-entity-status [entityStatus]="profile.status"></adxad-entity-status>
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          class="col-6"
          label="{{ 'firstName' | transloco }}"
          *ngIf="profile.firstName"
        >
          {{ profile.firstName }}
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          class="col-6"
          label="{{ 'lastName' | transloco }}"
          *ngIf="profile.lastName"
        >
          {{ profile.lastName }}
        </adxad-sidebar-modal-info-block>

        <ng-container *ngIf="roles.isPublisher()">
          <adxad-sidebar-modal-info-block
            class="col-6"
            label="{{ 'taxStatus' | transloco }}"
            *ngIf="profile.profile.tax.type"
          >
            {{ profile.profile.tax.type === 1 ? 'Individual' : 'Company' }}
          </adxad-sidebar-modal-info-block>

          <adxad-sidebar-modal-info-block
            class="col-6"
            label="{{ 'vatTax' | transloco }}"
            *ngIf="profile.profile.tax.vat"
          >
            {{ profile.profile.tax.vat }}
          </adxad-sidebar-modal-info-block>

          <adxad-sidebar-modal-info-block
            class="col-6"
            label="{{ 'companyName' | transloco }}"
            *ngIf="profile.profile.tax.companyName"
          >
            {{ profile.profile.tax.companyName }}
          </adxad-sidebar-modal-info-block>
        </ng-container>
      </div>
    </section>

    <section
      adxadSidebarModalSection
      *ngIf="roles.isPublisher()"
    >
      <h4
        class="profile-view__section-title"
        (click)="isOpenSection.accountManager = !isOpenSection.accountManager"
      >
        {{ 'accountManager' | transloco }}

        <span
          class="material-icons profile-view__section-title-icon"
          [class.open]="isOpenSection.accountManager"
        >
          keyboard_arrow_down
        </span>
      </h4>

      <div
        class="row"
        *ngIf="isOpenSection.accountManager"
        @slideToggle
      >
        <ng-container
          [ngTemplateOutlet]="
            profile.manager.avatar ? isAvatar : managerBio
          "
        ></ng-container>

        <ng-template #isAvatar>
          <div class="col-3">
            <img
              class="avatar"
              src="{{ profile.manager.avatar }}"
              alt="{{ profile.manager.firstName }} {{
                profile.manager.lastName
              }}"
            />
          </div>
          <div class="col-9">
            <div class="row">
              <ng-container [ngTemplateOutlet]="managerBio"></ng-container>
            </div>
          </div>
        </ng-template>

        <ng-template #managerBio>
          <adxad-sidebar-modal-info-block
            class="col-6"
            label="{{ 'firstName' | transloco }}"
            *ngIf="profile.manager.firstName"
          >
            {{ profile.manager.firstName }}
          </adxad-sidebar-modal-info-block>

          <adxad-sidebar-modal-info-block
            class="col-6"
            label="{{ 'lastName' | transloco }}"
            *ngIf="profile.manager.lastName"
          >
            {{ profile.manager.lastName }}
          </adxad-sidebar-modal-info-block>

          <adxad-sidebar-modal-info-block
            class="col-6"
            label="{{ 'email' | transloco }}"
            *ngIf="profile.manager.email"
          >
            {{ profile.manager.email }}
          </adxad-sidebar-modal-info-block>

          <adxad-sidebar-modal-info-block
            class="languages-list"
            [class]="profile.manager.avatar ? 'col-12' : 'col-6'"
            label="{{ 'language' | transloco }}"
            *ngIf="profile.manager.languages.length"
          >
            <span
              class="languages-list__item"
              *ngFor="let lang of profile.manager.languages"
            >
              {{ lang }}
            </span>
          </adxad-sidebar-modal-info-block>
        </ng-template>

        <adxad-sidebar-modal-info-block
          label="{{ 'messenger' | transloco }}"
          class="col-12"
          *ngIf="profile.manager.messengers.length"
        >
          <adxad-messengers-list
            [list]="profile.manager.messengers"
            [interactive]="true"
          ></adxad-messengers-list>
        </adxad-sidebar-modal-info-block>
      </div>
    </section>

    <section adxadSidebarModalSection>
      <h4
        class="profile-view__section-title"
        (click)="isOpenSection.contacts = !isOpenSection.contacts"
      >
        {{ 'contacts' | transloco }}

        <span
          class="material-icons profile-view__section-title-icon"
          [class.open]="isOpenSection.contacts"
          >keyboard_arrow_down</span
        >
      </h4>

      <div
        class="row"
        *ngIf="isOpenSection.contacts"
        @slideToggle
      >
        <adxad-sidebar-modal-info-block
          class="col-12"
          label="{{ 'phoneNumber' | transloco }}"
          *ngIf="profile.profile.phoneNumber"
        >
          {{ profile.profile.phoneNumber }}
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          label="{{ 'messenger' | transloco }}"
          class="col-12"
          *ngIf="profile.profile.messengers"
        >
          <adxad-messengers-list
            [list]="profile.profile.messengers"
            [interactive]="false"
          ></adxad-messengers-list>
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          class="col-6 languages-list"
          label="{{ 'language' | transloco }}"
          *ngIf="profile.profile.languages?.length"
        >
          <span
            class="languages-list__item"
            *ngFor="let lang of profile.profile.languages"
          >
            {{ lang }}
          </span>
        </adxad-sidebar-modal-info-block>
      </div>
    </section>

    <section
      adxadSidebarModalSection
      *ngIf="isViewAddress()"
    >
      <h4
        class="profile-view__section-title"
        (click)="isOpenSection.address = !isOpenSection.address"
      >
        {{ 'physicalAddress' | transloco }}

        <span
          class="material-icons profile-view__section-title-icon"
          [class.open]="isOpenSection.address"
          >keyboard_arrow_down</span
        >
      </h4>

      <div
        class="row"
        *ngIf="isOpenSection.address"
        @slideToggle
      >
        <adxad-sidebar-modal-info-block
          class="col-6"
          label="{{ 'country' | transloco }}"
          *ngIf="profile.profile.address.country"
        >
          {{ profile.profile.address.country }}
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          class="col-6"
          label="{{ 'city' | transloco }}"
          *ngIf="profile.profile.address.city"
        >
          {{ profile.profile.address.city }}
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          class="col-6"
          label="{{ 'zipCode' | transloco }}"
          *ngIf="profile.profile.address.zip"
        >
          {{ profile.profile.address.zip }}
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          class="col-6"
          label="{{ 'address' | transloco }}"
          *ngIf="profile.profile.address.address"
        >
          {{ profile.profile.address.address }}
        </adxad-sidebar-modal-info-block>
      </div>
    </section>

    <section
      adxadSidebarModalSection
      [hideSeparator]="true"
      *ngIf="isViewPayments()"
    >
      <h4
        class="profile-view__section-title"
        (click)="isOpenSection.payment = !isOpenSection.payment"
      >
        {{ 'paymentMethod' | transloco }}

        <span
          class="material-icons profile-view__section-title-icon"
          [class.open]="isOpenSection.payment"
        >
          keyboard_arrow_down
        </span>
      </h4>

      <div
        class="row"
        *ngIf="isOpenSection.payment"
        @slideToggle
      >
        <adxad-sidebar-modal-info-block
          class="col-6"
          label="{{ 'paymentMethod' | transloco }}"
          *ngIf="profile.payment.method"
        >
          {{ getPaymentMethod(profile.payment.method) }}
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          class="col-6"
          label="{{ 'wallet' | transloco }}"
          *ngIf="profile.payment.method !== 'transfer'"
        >
          {{ profile.payment.wallet }}
        </adxad-sidebar-modal-info-block>

        <ng-container *ngIf="profile.payment.method === 'transfer'">
          <adxad-sidebar-modal-info-block
            class="col-6"
            label="{{ 'paymentType' | transloco }}"
            *ngIf="profile.payment.type"
          >
            {{ profile.payment.type | titlecase }}
          </adxad-sidebar-modal-info-block>

          <adxad-sidebar-modal-info-block
            class="col-6"
            label="{{ 'companyName' | transloco }}"
            *ngIf="
              profile.payment.type === 'company' && profile.payment.companyName
            "
          >
            {{ profile.payment.companyName }}
          </adxad-sidebar-modal-info-block>

          <adxad-sidebar-modal-info-block
            class="col-6"
            label="SWIFT"
            *ngIf="profile.payment.SWIFT"
          >
            {{ profile.payment.SWIFT }}
          </adxad-sidebar-modal-info-block>

          <adxad-sidebar-modal-info-block
            class="col-6"
            label="IBAN"
            *ngIf="profile.payment.IBAN"
          >
            {{ profile.payment.IBAN }}
          </adxad-sidebar-modal-info-block>

          <adxad-sidebar-modal-info-block
            class="col-6"
            label="{{ 'beneficiaryName' | transloco }}"
            *ngIf="profile.payment.beneficiaryName"
          >
            {{ profile.payment.beneficiaryName }}
          </adxad-sidebar-modal-info-block>

          <adxad-sidebar-modal-info-block
            class="col-6"
            label="{{ 'beneficiaryAddress' | transloco }}"
            *ngIf="profile.payment.beneficiaryAddress"
          >
            {{ profile.payment.beneficiaryAddress }}
          </adxad-sidebar-modal-info-block>

          <adxad-sidebar-modal-info-block
            class="col-6"
            label="{{ 'bankAddress' | transloco }}"
            *ngIf="profile.payment.bankAddress"
          >
            {{ profile.payment.bankAddress }}
          </adxad-sidebar-modal-info-block>

          <adxad-sidebar-modal-info-block
            class="col-6"
            label="{{ 'phoneNumber' | transloco }}"
            *ngIf="profile.payment.phoneNumber"
          >
            {{ profile.payment.phoneNumber }}
          </adxad-sidebar-modal-info-block>
        </ng-container>
      </div>
    </section>
  </adxad-sidebar-modal-body>

  <adxad-sidebar-modal-actions>
    <button
      adxadSidebarModalAction
      actionType="main-gray"
      (click)="openPasswordForm()"
    >
      <span class="material-icons-outlined"> vpn_key </span>
      {{ 'changePassword' | transloco }}
    </button>

    <button
      class="profile-view__edit-btn"
      adxadSidebarModalAction
      actionType="main-blue"
      (click)="openEditForm()"
    >
      <span class="material-icons-outlined">edit</span>
      {{ 'edit' | transloco }}
    </button>
  </adxad-sidebar-modal-actions>
</adxad-sidebar-modal-container>
