<adxad-loader
  adxadModalLoader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<ng-container *ngIf="!isLoading">
  <adxad-modal-close-btn (click)="closeModal()"></adxad-modal-close-btn>

  <adxad-modal-header>
    <h2 adxadModalTitle>{{ 'changePassword' | transloco }}</h2>
  </adxad-modal-header>

  <adxad-modal-content [formGroup]="form">
    <adxad-form-field class="field-group">
      <adxad-input
        formControlName="oldPassword"
        [suffix]="true"
        label="{{ 'oldPassword' | transloco }}"
        [type]="showPassword ? 'text' : 'password'"
      >
        <span
          class="material-icons-outlined adxad-suffix"
          (click)="showPassword = !showPassword"
        >
          {{ showPassword ? 'visibility_off' : 'visibility' }}
        </span>
      </adxad-input>
      <adxad-show-errors [control]="form.get('oldPassword')"></adxad-show-errors>
    </adxad-form-field>

    <adxad-form-field class="field-group">
      <adxad-input
        formControlName="newPassword"
        [suffix]="true"
        label="{{ 'newPassword' | transloco }}"
        [type]="showPassword ? 'text' : 'password'"
      >
        <span
          class="material-icons-outlined adxad-suffix"
          (click)="showPassword = !showPassword"
        >
          {{ showPassword ? 'visibility_off' : 'visibility' }}
        </span>
      </adxad-input>
      <adxad-show-errors [control]="form.get('newPassword')"></adxad-show-errors>
    </adxad-form-field>
  </adxad-modal-content>

  <adxad-modal-actions>
    <button
      adxadStrokedButton
      (click)="closeModal()"
    >
      {{ 'cancel' | transloco }}
    </button>

    <button
      adxadButton
      [disabled]="!form.valid || !form.dirty || isLoadingSubmit"
      (click)="submit()"
    >
      <adxad-loader
        class="submit-loader"
        [diameter]="20"
        *ngIf="isLoadingSubmit"
      ></adxad-loader>

      <span [ngClass]="{ loading: isLoadingSubmit }"> {{ 'save' | transloco }} </span>
    </button>
  </adxad-modal-actions>
</ng-container>
