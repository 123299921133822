<div
  class="adxad-menu"
  [class.opened]="layoutService.isMenuStaticOpened || isNavBarOpen"
  (mouseenter)="openMenu()"
  (mouseleave)="closeMenu()"
>
  <ng-container *ngFor="let item of menuConfig">
    <div
      class="adxad-nav-btn"
      data-test="menu-item"
      *ngIf="!item.hide"
    >
      <a
        class="adxad-nav-btn__link"
        [routerLink]="item.route"
        routerLinkActive="active"
        [class._has-child]="item.items?.length"
      >
        <span class="adxad-nav-btn__icon material-icons-outlined"> {{ item.icon }} </span>

        <span
          class="adxad-nav-btn__label"
          [class._hidden]="!layoutService.isMenuStaticOpened && !isNavBarOpen"
        >
          {{ item.translate ? (item.name | transloco) : item.name }}
        </span>

        <div
          *ngIf="item.items?.length"
          class="adxad-nav-btn__open-btn"
          [class.opened]="item.isOpenSubMenu"
          (click)="toggleSubMenu($event, item)"
        >
          <span
            class="adxad-nav-btn__open-btn-icon material-icons-outlined"
            *ngIf="isNavBarOpen || layoutService.isMenuStaticOpened"
            [class.opened]="item.isOpenSubMenu"
          >
            keyboard_arrow_down
          </span>
        </div>
      </a>

      <div
        class="adxad-nav-btn__submenu"
        *ngIf="
          item.isOpenSubMenu &&
          (layoutService.isMenuStaticOpened || isNavBarOpen)
        "
        @slideToggle
      >
        <div
          class="adxad-nav-btn__submenu-item"
          *ngFor="let subItem of item.items"
          [routerLink]="subItem.route"
          routerLinkActive="active"
        >
          {{ subItem.name }}
        </div>
      </div>
    </div>
  </ng-container>

  <div class="adxad-menu__spacer"></div>

  <div
    class="adxad-menu__more-btn"
    [class.active]="layoutService.isMenuStaticOpened"
    (click)="layoutService.toggleMenuStaticOpened()"
  >
    <span class="material-icons-outlined"> keyboard_arrow_right </span>
  </div>
</div>
