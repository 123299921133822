import { NgModule } from '@angular/core';
import { UserProfileViewComponent } from './components/user-profile-view/user-profile-view.component';
import { UserProfileFormComponent } from './components/user-profile-form/user-profile-form.component';
import { UserChangePasswordFormComponent } from './components/user-change-password-form/user-change-password-form.component';
import { TranslocoModule } from '@jsverse/transloco';
import { GuiModule } from '../../gui/gui.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [UserProfileViewComponent, UserProfileFormComponent, UserChangePasswordFormComponent],
  imports: [TranslocoModule, GuiModule, ReactiveFormsModule, CommonModule]
})
export class UserProfileModule {}
