import { Routes } from '@angular/router';
import { MainComponent } from './layout/components/main/main.component';
import { MainGuard } from './core/guards/main.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { APP_ROUTE } from './core/routes';

export const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [MainGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () => import('./layout/components/check-route/check-route.component').then(c => c.CheckRouteComponent)
      },
      {
        path: APP_ROUTE.spots,
        loadComponent: () => import('./modules/spots/components/spots-grid/spots-grid.component').then(c => c.SpotsGridComponent),
        title: 'Spots',
        data: {
          pageType: 'SPOTS'
        }
      },
      {
        path: APP_ROUTE.publishers,
        loadComponent: () =>
          import('./modules/publishers/components/publishers-grid/publishers-grid.component').then(c => c.PublishersGridComponent),
        title: 'Publishers',
        data: {
          pageType: 'PUBLISHERS'
        }
      },
      {
        path: APP_ROUTE.tariffs,
        loadComponent: () => import('./modules/tariffs/components/tariffs-grid/tariffs-grid.component').then(c => c.TariffsGridComponent),
        title: 'Tariffs',
        data: {
          pageType: 'TARIFFS'
        }
      },
      {
        path: APP_ROUTE.connectedDsps,
        loadComponent: () =>
          import('./modules/connected-dsps/components/connected-dsps-grid/connected-dsps-grid.component').then(
            c => c.ConnectedDspsGridComponent
          ),
        title: 'DSP sets',
        data: {
          pageType: 'DSP_SETS'
        }
      },
      {
        path: APP_ROUTE.guidelines,
        loadComponent: () =>
          import('./modules/guidelines/components/guidlines-page/guidelines-page.component').then(c => c.GuidelinesPageComponent),
        title: 'Guidelines',
        data: {
          pageType: 'GUIDELINES'
        }
      },
      {
        path: APP_ROUTE.statistic,
        loadComponent: () =>
          import('./modules/statistic/components/statistic-grid/statistic-grid.component').then(c => c.StatisticGridComponent),
        title: 'Statistics',
        data: {
          pageType: 'STATISTICS'
        }
      },
      {
        path: APP_ROUTE.users,
        loadComponent: () => import('./modules/users/components/users-grid/users-grid.component').then(c => c.UsersGridComponent),
        title: 'Managers & Administrators',
        data: {
          pageType: 'USERS'
        }
      },
      {
        path: APP_ROUTE.payouts,
        loadComponent: () => import('./modules/payouts/components/payouts-grid/payouts-grid.component').then(c => c.PayoutsGridComponent),
        title: 'Payouts',
        data: {
          pageType: 'PAYOUTS'
        }
      },
      {
        path: APP_ROUTE.translations,
        loadComponent: () =>
          import('./transloco/components/translations-grid/translations-grid.component').then(c => c.TranslationsGridComponent),
        title: 'Translations'
      },
      {
        path: APP_ROUTE.events,
        loadComponent: () =>
          import('./modules/events-log/components/events-log-grid/events-log-grid.component').then(c => c.EventsLogGridComponent),
        title: 'Events log'
      }
    ]
  },
  {
    path: APP_ROUTE.auth,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: '**',
    loadComponent: () => import('./gui/components/not-found/not-found.component').then(c => c.NotFoundComponent),
    title: 'Page not found'
  }
];
