import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { AdxadSidebarModalRef } from '../../../../ui/modules/sidebar-modal/sidebar-modal-ref';
import { AdxadModal } from '../../../../ui/modules/modal/modal.service';
import { slideToggleAnimation } from '../../../../ui/animations';
import { SspService } from '../../../../core/services/ssp.service';
import { takeUntil } from 'rxjs/operators';
import { AlertsComponent } from '../../../../ui/components/alerts/alerts.component';
import { RolesService } from '../../../../core/services/roles.service';
import { AuthService } from '../../../../auth/auth.service';
import { UserProfileFormComponent } from '../user-profile-form/user-profile-form.component';
import { UserChangePasswordFormComponent } from '../user-change-password-form/user-change-password-form.component';
import { PayoutMethod, ProfileDetail } from '../../../../core/interfaces';
import { QueryParamsService } from '../../../../core/services/query-params.service';
import { ProfileService } from '../../../../core/services/profile.service';
import { PayoutOrderFormComponent } from '../../../payouts/components/payout-order-form/payout-order-form.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-user-profile-view',
  templateUrl: './user-profile-view.component.html',
  styleUrls: ['./user-profile-view.component.scss'],
  animations: [slideToggleAnimation]
})
export class UserProfileViewComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public isOpenSection = {
    account: true,
    accountManager: true,
    contacts: true,
    payment: true,
    address: true
  };

  private destroyRef = inject(DestroyRef);

  constructor(
    private modalRef: AdxadSidebarModalRef,
    private modal: AdxadModal,
    private sspService: SspService,
    private authService: AuthService,
    private alerts: AlertsComponent,
    public roles: RolesService,
    private profileService: ProfileService,
    private queryParamsService: QueryParamsService
  ) {}

  ngOnInit(): void {
    this.queryParamsService.add('sm_profileView', true);
  }

  get profile(): ProfileDetail {
    return this.profileService.profile;
  }

  /**
   * Close modal
   */
  closeModal(): void {
    this.modalRef.close();
  }

  /**
   * Reload profile data
   */
  reloadProfile(): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    this.profileService.getProfile().subscribe({
      next: result => {
        this.isLoading = false;

        if (result.status === 'OK') {
          this.profileService.setProfile(result.data as ProfileDetail);
        }
      },
      error: () => (this.isLoading = false)
    });
  }

  openEditForm() {
    this.modal
      .open(UserProfileFormComponent, {
        width: '680px',
        data: {}
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: value => {
          if (value && value.submit) {
            this.reloadProfile();
          }
        }
      });
  }

  /**
   * Open password form
   */
  openPasswordForm(): void {
    this.modal.open(UserChangePasswordFormComponent, {
      width: '560px'
    });
  }

  /**
   * Return true if roles is publisher and has address
   */
  isViewAddress(): boolean {
    return this.roles.isPublisher() && Object.values(this.profile.profile.address).some(val => val);
  }

  /**
   * Return true if roles is publisher and has address
   */
  isViewPayments(): boolean {
    return this.roles.isPublisher() && Object.values(this.profile.payment).some(val => val);
  }

  /**
   * Open order payout form
   */
  orderPayout(): void {
    this.modal.open(PayoutOrderFormComponent, {
      width: '640px',
      data: this.profile
    });
  }

  /**
   * Return payment method name
   * @param paymentMethod
   */
  getPaymentMethod(paymentMethod: string): string {
    return PayoutMethod[paymentMethod];
  }

  ngOnDestroy(): void {
    this.queryParamsService.remove('sm_profileView');
  }
}
