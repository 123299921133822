import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SentryService {
  private readonly prodStageUrl = 'pub.adxad.com';

  initSentry() {
    if (environment.production && location.host === this.prodStageUrl) {
      Sentry.init({
        dsn: 'https://55009439777947b38b39655ceb450a89@pub.adxad.com/2',
        environment: 'production',
        tunnel: '/sntr',
        debug: false,
        release: '107.1'
      });
    }
  }
}
