import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariffCalendarComponent } from './components/tariff-calendar/tariff-calendar.component';
import { SetTariffFormComponent } from './components/set-tariff-form/set-tariff-form.component';
import { TranslocoModule } from '@jsverse/transloco';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GuiModule } from '../../gui/gui.module';
import { CoreModule } from '../../core/core.module';
import { TariffViewComponent } from './components/tariff-view/tariff-view.component';
import { TariffFormComponent } from './components/tariff-form/tariff-form.component';
import { DynamicFilterModule } from '../../dynamic-modules/dynamic-filter/dynamic-filter.module';
import { MoveAllSpotsFormComponent } from './components/move-all-spots-form/move-all-spots-form.component';
import { TariffModelComponent } from './components/tariff-model/tariff-model.component';

const ENTRY_COMPONENTS = [
  TariffCalendarComponent,
  SetTariffFormComponent,
  TariffViewComponent,
  TariffFormComponent,
  MoveAllSpotsFormComponent
];

@NgModule({
  declarations: [...ENTRY_COMPONENTS, TariffModelComponent],
  imports: [CommonModule, TranslocoModule, ReactiveFormsModule, GuiModule, CoreModule, FormsModule, DynamicFilterModule],
  exports: [TariffModelComponent]
})
export class TariffsModule {}
