import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuiModule } from '../../gui/gui.module';

import { SpotViewComponent } from './components/spot-view/spot-view.component';
import { SpotFormComponent } from './components/spot-form/spot-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpotModificationHistoryComponent } from './components/spot-modification-history/spot-modification-history.component';
import { CoreModule } from '../../core/core.module';
import { SpotBlockingReasonComponent } from './components/spot-blocking-reason/spot-blocking-reason.component';
import { SpotCommentFormComponent } from './components/spot-comment-form/spot-comment-form.component';
import { SpotCodeViewComponent } from './components/spot-code-view/spot-code-view.component';
import { DynamicFilterModule } from '../../dynamic-modules/dynamic-filter/dynamic-filter.module';
import { TariffsModule } from '../tariffs/tariffs.module';
import { MassShavingRateComponent } from './components/mass-shaving-rate/mass-shaving-rate.component';
import { SafeHtmlPipe } from '../../core/pipes/safe-html.pipe';
import { TranslocoPipe } from '@jsverse/transloco';

const ENTRY_COMPONENTS = [
  SpotViewComponent,
  SpotFormComponent,
  SpotModificationHistoryComponent,
  SpotBlockingReasonComponent,
  SpotCommentFormComponent,
  SpotCodeViewComponent,
  MassShavingRateComponent
];

@NgModule({
  declarations: [...ENTRY_COMPONENTS],
  imports: [
    CommonModule,
    GuiModule,
    ReactiveFormsModule,
    CoreModule,
    DynamicFilterModule,
    TariffsModule,
    FormsModule,
    SafeHtmlPipe,
    TranslocoPipe
  ]
})
export class SpotsModule {}
