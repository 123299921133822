import { inject } from '@angular/core';
import { HttpInterceptorFn } from '@angular/common/http';

import { AuthService } from '../../auth/auth.service';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);

  if (req.url.includes('auth') || !authService.hasToken) {
    return next(req);
  }

  req = req.clone({
    setHeaders: {
      Authorization: `Bearer ${authService.accessToken}`
    }
  });

  return next(req);
};
