import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuiModule } from '../../gui/gui.module';
import { ConnectedDspFormComponent } from './components/connected-dsp-form/connected-dsp-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConnectedDspTrafficFilterComponent } from './components/connected-dsp-traffic-filter/connected-dsp-traffic-filter.component';
import { ConnectedDspViewComponent } from './components/connected-dsp-view/connected-dsp-view.component';
import { CoreModule } from '../../core/core.module';

const ENTRY_COMPONENTS = [ConnectedDspFormComponent, ConnectedDspTrafficFilterComponent, ConnectedDspViewComponent];

@NgModule({
  declarations: [...ENTRY_COMPONENTS],
  imports: [CommonModule, GuiModule, ReactiveFormsModule, FormsModule, CoreModule]
})
export class ConnectedDspsModule {}
