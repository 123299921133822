<ng-container
  [ngTemplateOutlet]="
    checkUserAgent.isMobile ? isMobileView : isDesktopView
  "
></ng-container>

<ng-template #isMobileView>
  <mat-sidenav-container (backdropClick)="closeSidenav()">
    <mat-sidenav
      #sidenav
      disableClose
      position="end"
      [fixedInViewport]="true"
    >
      <div class="sidenav-header">
        <img
          class="sidenav-header__logo"
          src="/assets/images/logo_ad.svg"
          alt=""
        />
        <img
          src="/assets/images/svg/close.svg"
          alt=""
          (click)="closeSidenav()"
        />
      </div>
      <nav class="sidenav__navigation">
        <a
          class="sidenav__navigation-item"
          href="https://adxad.com/"
        >
          {{ 'homepage' | transloco }}
        </a>
        <a
          class="sidenav__navigation-item"
          href="https://adxad.com/{{ transloco.getActiveLang() }}/advertisers"
        >
          {{ 'forAdvertisers' | transloco }}
        </a>
        <a
          class="sidenav__navigation-item"
          href="https://adxad.com/{{ transloco.getActiveLang() }}/publishers"
        >
          {{ 'forPublishers' | transloco }}
        </a>
        <a
          class="sidenav__navigation-item"
          href="https://adxad.com/{{ transloco.getActiveLang() }}/team"
        >
          {{ 'about' | transloco }}
        </a>
        <div
          class="sidenav__navigation-item"
          (click)="toggleSubmenu()"
        >
          {{ 'help' | transloco }}
        </div>
        <div
          class="submenu"
          *ngIf="showSubmenu"
        >
          <a
            class="sidenav__navigation-item"
            href="https://adxad.com/{{ transloco.getActiveLang() }}/help-trade"
          >
            – {{ 'tradeDeskHelp' | transloco }}
          </a>
          <a
            class="sidenav__navigation-item"
            href="https://adxad.com/{{ transloco.getActiveLang() }}/help"
          >
            – {{ 'spec' | transloco }}
          </a>
          <a
            class="sidenav__navigation-item"
            href="https://adxad.com/{{ transloco.getActiveLang() }}/faq"
          >
            – FAQ
          </a>
          <a
            class="sidenav__navigation-item"
            href="https://adxad.com/{{
              transloco.getActiveLang()
            }}/guid-advertisers"
          >
            – {{ 'guidelinesForAdvertisers' | transloco }}
          </a>
          <a
            class="sidenav__navigation-item"
            href="https://adxad.com/{{
              transloco.getActiveLang()
            }}/guid-publishers"
          >
            – {{ 'guidelinesForPublishers' | transloco }}
          </a>
          <a
            class="sidenav__navigation-item"
            href="https://adxad.com/{{
              transloco.getActiveLang()
            }}/conversions-import-service"
          >
            – {{ 'conversionsImportService' | transloco }}
          </a>
        </div>

        <a
          class="sidenav__navigation-item"
          href="https://adxad.com/{{ transloco.getActiveLang() }}/contacts"
        >
          {{ 'contactUs' | transloco }}
        </a>
      </nav>
    </mat-sidenav>

    <mat-sidenav-content>
      <header class="sidenav-content__header">
        <img
          class="sidenav-content__header-logo"
          src="/assets/images/logo_ad.svg"
          alt=""
        />

        <div class="sidenav-content__header-actions">
          <adxad-language-picker localesListPosition="right"></adxad-language-picker>
          <img
            src="/assets/images/svg/menu.svg"
            alt=""
            (click)="sidenav.open()"
          />
        </div>
      </header>

      <div class="sidenav-content__body">
        <form
          [formGroup]="form"
          class="form"
          *ngIf="stage === 1"
        >
          <img
            src="/assets/images/svg/check-circle.svg"
            alt=""
            class="check-circle"
          />

          <fieldset class="form-fieldset">
            <legend class="form-legend">
              <span class="title"> {{ 'thankYouForRegistration' | transloco }} </span>
              <span class="subtitle"> {{ 'lastStep' | transloco }} </span>
              <span class="notice"> {{ 'pleaseLeaveYourContacts' | transloco }} </span>
            </legend>

            <ng-container formGroupName="messengers">
              <div class="form-field --select">
                <select
                  class="form-select"
                  formControlName="type"
                >
                  <option
                    value=""
                    disabled
                    selected
                  >
                    {{ 'chooseMessenger' | transloco }}
                  </option>
                  <option
                    *ngFor="let messenger of dicts.messengers?.data"
                    [value]="messenger.id"
                  >
                    {{ messenger.value }}
                  </option>
                </select>
                <adxad-show-errors [control]="messengerType"></adxad-show-errors>
              </div>

              <div class="form-field">
                <input
                  class="form-input"
                  placeholder="{{ 'account' | transloco }}"
                  formControlName="info"
                  type="text"
                />
                <adxad-show-errors [control]="messengerInfo"></adxad-show-errors>
              </div>
            </ng-container>

            <ng-container formGroupName="feedback">
              <div class="form-field --select">
                <select
                  class="form-select"
                  formControlName="type"
                  (change)="onChangeVariant($event)"
                >
                  <option
                    value=""
                    disabled
                    selected
                  >
                    {{ 'howDidYouLearnAboutUs' | transloco }}
                  </option>

                  <option
                    *ngFor="let feedback of dicts.feedback.data"
                    [value]="feedback.id"
                  >
                    {{ feedback.value | transloco }}
                  </option>
                </select>
                <adxad-show-errors [control]="feedbackType"></adxad-show-errors>
              </div>

              <div
                class="form-field --select"
                *ngIf="show == 1"
              >
                <select
                  class="form-select"
                  formControlName="info"
                >
                  <option
                    *ngFor="let social of dicts.socials.data"
                    [value]="social.id"
                  >
                    {{ social.value | transloco }}
                  </option>
                </select>
              </div>

              <div
                class="form-field"
                *ngIf="show == 2"
              >
                <input
                  class="form-input"
                  placeholder="{{ feedbackPlaceholder | transloco }}"
                  formControlName="info"
                  type="text"
                />
                <adxad-show-errors [control]="feedbackInfo"></adxad-show-errors>
              </div>
            </ng-container>

            <button
              class="form-btn"
              type="submit"
              [disabled]="form.invalid"
              (click)="submit()"
            >
              {{ 'send' | transloco }}
            </button>
          </fieldset>
        </form>

        <ng-container *ngIf="stage === 2">
          <div class="welcome">
            <div class="welcome__title">
              {{ 'dear' | transloco }} {{ profileService.profile.firstName }},
              <br />
              {{ 'welcomeToAdvertiserAccount' | transloco }}
            </div>
            <p>{{ 'pleaseBeAwareThatForAWhileWeHighlyRecommend' | transloco }}</p>
            <button
              class="form-btn"
              type="button"
              (click)="goTo()"
            >
              {{ 'okay' | transloco }}
            </button>
          </div>
        </ng-container>
      </div>

      <footer class="sidenav-content__footer">
        <a
          href="mailto:services@adxad.com"
          class="contact-email"
        >
          services&#64;adxad.com
        </a>
        <a
          href="tel:+85281924980"
          class="contact-phone"
        >
          +852-8192-4980
        </a>

        <div class="copyright">© {{ today | date: 'yyyy' }} ADxAD. All Rights Reserved.</div>

        <nav class="sidenav-content__footer-nav">
          <a href="https://adxad.com/{{ transloco.getActiveLang() }}/publishers"> {{ 'forPublishers' | transloco }} </a>
          <a
            href="https://adxad.com/{{
              transloco.getActiveLang()
            }}/advertisers"
          >
            {{ 'forAdvertisers' | transloco }}
          </a>
          <a href="https://adxad.com/{{ transloco.getActiveLang() }}/team"> {{ 'about' | transloco }} </a>
          <a href="https://adxad.com/{{ transloco.getActiveLang() }}/contacts"> {{ 'support' | transloco }} </a>
          <a
            href="https://adxad.com/{{
              transloco.getActiveLang()
            }}/privacy-policy"
          >
            {{ 'privacyPolicy' | transloco }}
          </a>
          <a href="https://adxad.com/{{ transloco.getActiveLang() }}/terms"> {{ 'termsConditions' | transloco }} </a>
          <a
            href="https://adxad.com/{{
              transloco.getActiveLang()
            }}/guid-advertisers"
          >
            {{ 'guidelinesForAdvertisers' | transloco }}
          </a>
          <a
            href="https://adxad.com/{{
              transloco.getActiveLang()
            }}/guid-publishers"
          >
            {{ 'guidelinesForPublishers' | transloco }}
          </a>
        </nav>
      </footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-template>

<ng-template #isDesktopView>
  <adxad-loader
    class="loader"
    [diameter]="40"
    *ngIf="isLoading"
  ></adxad-loader>

  <ng-container *ngIf="!isLoading">
    <h2>{{ 'pleaseLeaveYourContacts' | transloco }}</h2>
    <p>{{ 'weNeedYourContactForBetterWork' | transloco }}</p>

    <form [formGroup]="form">
      <ng-container formGroupName="messengers">
        <adxad-form-field class="field-group">
          <adxad-select
            placeholder="{{ 'selectMessenger' | transloco }}"
            label="{{ 'messenger' | transloco }}"
            formControlName="type"
            [options]="dicts.messengers.data"
          ></adxad-select>
          <adxad-show-errors [control]="messengerType"></adxad-show-errors>
        </adxad-form-field>

        <adxad-form-field class="field-group">
          <adxad-input
            formControlName="info"
            label="{{ 'account' | transloco }}"
            placeholder="{{ 'enterAccount' | transloco }}"
          ></adxad-input>
          <adxad-show-errors [control]="messengerInfo"></adxad-show-errors>
        </adxad-form-field>
      </ng-container>

      <h4 class="user-form__title">{{ 'howDidYouHearAboutUs' | transloco }}</h4>

      <ng-container formGroupName="feedback">
        <adxad-form-field class="field-group">
          <adxad-select
            placeholder="{{ 'selectVariant' | transloco }}"
            label="{{ 'select' | transloco }}"
            formControlName="type"
            [options]="dicts.feedback.data"
            [translateOptions]="true"
            (selectOption)="onChangeVariant($event)"
          ></adxad-select>
          <adxad-show-errors [control]="feedbackType"></adxad-show-errors>
        </adxad-form-field>

        <adxad-form-field
          class="field-group"
          *ngIf="show === 1"
        >
          <adxad-select
            placeholder="{{ 'socialNetworks' | transloco }}"
            label="{{ 'selectSocialNetwork' | transloco }}"
            formControlName="info"
            [options]="dicts.socials.data"
          ></adxad-select>
          <adxad-show-errors [control]="feedbackInfo"></adxad-show-errors>
        </adxad-form-field>

        <adxad-form-field
          class="field-group"
          *ngIf="show === 2"
        >
          <adxad-input
            formControlName="info"
            [label]="feedbackPlaceholder | transloco"
          ></adxad-input>
          <adxad-show-errors [control]="feedbackInfo"></adxad-show-errors>
        </adxad-form-field>
      </ng-container>

      <button
        adxadButton
        [disabled]="!form.valid || !form.dirty"
        class="w100"
        type="submit"
        (click)="submit()"
      >
        {{ 'save' | transloco }}
      </button>
    </form>
  </ng-container>
</ng-template>
