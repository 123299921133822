import { Component, inject, OnInit } from '@angular/core';
import { AppLocalesService } from './core/services/app-locales.service';
import { GlobicaService } from './core/services/globica.service';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ReplaySessionService } from './core/services/replay-session.service';
import { AuthService } from './auth/auth.service';
import { GuiModule } from './gui/gui.module';

@Component({
  selector: 'adxad-ssp-root',
  template: `
    <adxad-cookies-popup />
    <router-outlet />
  `,
  styles: [
    `
      :host {
        min-height: 100%;
      }
    `
  ],
  standalone: true,
  imports: [GuiModule, RouterOutlet]
})
export class AppComponent implements OnInit {
  authService = inject(AuthService);

  private appLocalesService = inject(AppLocalesService);
  private globicaService = inject(GlobicaService);
  private router = inject(Router);
  private gtmService = inject(GoogleTagManagerService);
  private openReplayService = inject(ReplaySessionService);

  ngOnInit(): void {
    this.appLocalesService.listenLocale();
    this.appLocalesService.init();
    this.globicaService.init();
    this.openReplayService.start();

    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };

        this.gtmService.pushTag(gtmTag);
      }
    });
  }
}
