import { NgModule } from '@angular/core';
import { UsersGridComponent } from './components/users-grid/users-grid.component';
import { GuiModule } from '../../gui/gui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../../core/core.module';
import { DynamicFilterModule } from '../../dynamic-modules/dynamic-filter/dynamic-filter.module';
import { UserFormComponent } from './components/user-form/user-form.component';
import { CommonModule } from '@angular/common';
import { UserViewComponent } from './components/user-view/user-view.component';
import { TranslocoModule } from '@jsverse/transloco';

const ENTRY_MODULES = [ReactiveFormsModule, CoreModule, FormsModule, CommonModule, GuiModule, DynamicFilterModule];
const ENTRY_COMPONENTS = [UserFormComponent, UserViewComponent];

@NgModule({
  declarations: [...ENTRY_COMPONENTS],
  imports: [...ENTRY_MODULES, TranslocoModule]
})
export class UsersModule {}
