<adxad-loader
  class="shadow-loader"
  [diameter]="40"
  *ngIf="isLoading"
/>

<ng-container *ngIf="!isLoading">
  <header class="header">
    <img
      src="assets/images/logo_ad.svg"
      alt=""
      class="header__logo"
      routerLink="/"
    />
    <div class="spacer"></div>

    <adxad-support-messengers
      label="{{ 'yourManager' | transloco }}"
      [manager]="profile.manager"
      *ngIf="profile.manager"
    />

    <section
      class="header__balance"
      *ngIf="roles.isPublisher()"
    >
      {{ 'label_yourBalance' | transloco }}

      <span class="header__balance-amount"> {{ profile.balance | currency }} </span>

      <button
        adxadButton
        class="red"
        [disabled]="profile.balance < 50"
        (click)="orderPayout()"
      >
        {{ 'action_orderPayout' | transloco }}
      </button>
    </section>

    <div
      class="adxad-profile"
      (click)="openProfile()"
      *ngIf="profile.profile.feedback"
    >
      {{ profile.email }}
    </div>

    <adxad-language-picker
      localesListPosition="right"
      [isAdmins]="!roles.isPublisher()"
    />
    <span
      class="material-icons-outlined logout"
      (click)="logout()"
      routerLink="{{ appRoute.auth }}"
    >
      logout
    </span>
  </header>

  <adxad-menu *ngIf="isShowSidebar" />

  <adxad-contact-quiz *ngIf="layoutService.isNeedContactQuiz" />

  <ng-container *ngIf="profile.profile.feedback">
    <main
      class="main"
      [class.sidebar-open]="layoutService.isMenuStaticOpened"
      [class.hide-sidebar]="!isShowSidebar"
    >
      <router-outlet />
    </main>
    <adxad-banner />
  </ng-container>
</ng-container>
