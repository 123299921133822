import { Component } from '@angular/core';
import { slideToggleAnimation } from '../../../ui/animations';
import { RolesService } from '../../../core/services/roles.service';
import { LayoutService } from '../../layout.service';
import { MenuItem } from '../../../core/interfaces';
import { APP_ROUTE } from '../../../core/routes';

@Component({
  selector: 'adxad-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [slideToggleAnimation]
})
export class MenuComponent {
  public isNavBarOpen = false;
  public menuConfig: MenuItem[] = [
    // {
    //   id: 'dashboard',
    //   name: 'Dashboard',
    //   translate: false,
    //   icon: 'dashboard',
    //   route: APP_ROUTE.dashboard,
    //   hide: this.roles.isPublisher()
    // },
    {
      id: 'statistic',
      name: 'statistic',
      translate: true,
      icon: 'query_stats',
      route: APP_ROUTE.statistic
    },
    {
      id: 'spots',
      name: 'spots',
      translate: true,
      icon: 'dashboard_customize',
      route: APP_ROUTE.spots
    },
    {
      id: 'publishers',
      name: 'publishers',
      translate: true,
      icon: 'sentiment_satisfied',
      route: APP_ROUTE.publishers,
      hide: this.roles.isPublisher()
    },
    {
      id: 'tariffs',
      name: 'tariffs',
      translate: true,
      icon: 'sell',
      route: APP_ROUTE.tariffs,
      hide: this.roles.isPublisher()
    },
    {
      id: 'users',
      name: 'Managers & Administrators',
      translate: false,
      icon: 'sentiment_very_satisfied',
      route: APP_ROUTE.users,
      hide: !this.roles.isAdmin()
    },
    {
      id: 'payouts',
      name: 'Payouts',
      translate: false,
      icon: 'currency_exchange',
      route: APP_ROUTE.payouts
    },
    {
      id: 'connectedDsps',
      name: 'DSP sets',
      translate: false,
      icon: 'difference',
      route: APP_ROUTE.connectedDsps,
      hide: !this.roles.isAdmin()
    },
    {
      id: 'translations',
      name: 'Translations',
      translate: false,
      icon: 'translate',
      route: APP_ROUTE.translations,
      hide: !this.roles.isAdmin()
    },
    {
      id: 'events',
      name: 'Events log',
      translate: false,
      icon: 'local_activity',
      route: APP_ROUTE.events,
      hide: !this.roles.isAdmin()
    },
    {
      id: 'guidelines',
      name: 'guidelines',
      translate: true,
      icon: 'help_outline',
      route: APP_ROUTE.guidelines
    }
  ];

  constructor(
    private roles: RolesService,
    public layoutService: LayoutService
  ) {}

  /**
   * Load from local storage is open submenu flag
   * @param {string} id of menu item
   */
  isOpenMenuItem(id: string): boolean {
    return localStorage.getItem('isOpenMenuItem:' + id) === 'true';
  }

  /**
   * Open menu
   */
  openMenu(): void {
    this.isNavBarOpen = true;
  }

  /**
   * Close menu
   */
  closeMenu(): void {
    if (this.layoutService.isMenuStaticOpened) {
      return;
    }

    this.isNavBarOpen = false;
  }

  /**
   * Show/hide sub menu
   * @param e
   * @param item
   */
  toggleSubMenu(e: MouseEvent, item: MenuItem): void {
    e.stopPropagation();
    e.preventDefault();

    item.isOpenSubMenu = !item.isOpenSubMenu;
    localStorage.setItem('isOpenMenuItem:' + item.id, item.isOpenSubMenu.toString());
  }
}
