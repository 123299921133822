import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReplaySessionService } from '../services/replay-session.service';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class OpenReplayHttpConfigInterceptor implements HttpInterceptor {
  constructor(private replaySessionService: ReplaySessionService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const handleResponse = (request: HttpRequest<any>, response: HttpResponse<any>, event: string) => {
      this.replaySessionService.sendEventToReplaySession(event, {
        request,
        response
      });
    };
    return next.handle(request).pipe(
      filter((event: any) => event instanceof HttpResponse),
      map((resp: HttpResponse<any>) => {
        //for each response, call handleResponse
        handleResponse(request, resp, `${request.url}`);
        return resp;
      }),
      map((event: HttpEvent<any>) => {
        return event;
      })
    );
  }
}
