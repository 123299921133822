import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MainComponent } from './components/main/main.component';
import { RouterModule } from '@angular/router';
import { GuiModule } from '../gui/gui.module';
import { ContactQuizComponent } from './components/contact-quiz/contact-quiz.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CheckRouteComponent } from './components/check-route/check-route.component';
import { MenuComponent } from './components/menu/menu.component';

@NgModule({
  declarations: [MainComponent, ContactQuizComponent, MenuComponent],
  imports: [CommonModule, RouterModule, GuiModule, ReactiveFormsModule, TranslocoModule, MatSidenavModule, NgOptimizedImage]
})
export class LayoutModule {}
