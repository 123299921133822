import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { APP_ROUTE } from '../../../core/routes';
import { RolesService } from '../../../core/services/roles.service';
import { AdxadSidebarModal } from '../../../ui/modules/sidebar-modal/sidebar-modal.service';
import { LayoutService } from '../../layout.service';
import { takeUntil } from 'rxjs/operators';
import { ProfileDetail } from '../../../core/interfaces';
import { AlertsComponent } from '../../../ui/components/alerts/alerts.component';
import { Router } from '@angular/router';
import { UserProfileViewComponent } from '../../../modules/user-profile/components/user-profile-view/user-profile-view.component';
import { QueryParamsService } from '../../../core/services/query-params.service';
import { ConnectedDspViewComponent } from '../../../modules/connected-dsps/components/connected-dsp-view/connected-dsp-view.component';
import { PublisherViewComponent } from '../../../modules/publishers/components/publisher-view/publisher-view.component';
import { SpotViewComponent } from '../../../modules/spots/components/spot-view/spot-view.component';
import { TariffViewComponent } from '../../../modules/tariffs/components/tariff-view/tariff-view.component';
import { UserViewComponent } from '../../../modules/users/components/user-view/user-view.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ProfileService } from '../../../core/services/profile.service';
import { IsMobileService } from '../../../core/services/is-mobile.service';
import { PayoutOrderFormComponent } from '../../../modules/payouts/components/payout-order-form/payout-order-form.component';
import { AdxadModal } from '../../../ui/modules/modal/modal.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-ssp-private-layout',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  readonly appRoute = APP_ROUTE;
  isLoading = false;

  private destroyRef = inject(DestroyRef);

  constructor(
    public authService: AuthService,
    public roles: RolesService,
    private sidebarModal: AdxadSidebarModal,
    public layoutService: LayoutService,
    private alerts: AlertsComponent,
    private router: Router,
    private queryParamsService: QueryParamsService,
    private gtmService: GoogleTagManagerService,
    public profileService: ProfileService,
    public checkUserAgent: IsMobileService,
    private modal: AdxadModal
  ) {}

  ngOnInit() {
    this.loadProfile();
  }

  /**
   * @return {ProfileDetail} profile data
   */
  public get profile(): ProfileDetail {
    return this.profileService.profile;
  }

  /**
   * Load user profile data
   */
  loadProfile(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.profileService
      .getProfile()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          if (result.status === 'OK') {
            this.profileService.setProfile(result.data as ProfileDetail);
            this.checkSidebar();
          }
        },
        complete: () => (this.isLoading = false),
        error: () => (this.isLoading = false)
      });
  }

  logout() {
    this.authService.logout();
  }

  /**
   * Open profile view in sidebar modal
   */
  openProfile(): void {
    this.sidebarModal.open(UserProfileViewComponent);
  }

  /**
   * Check sm data in query params
   * May be opened only one modal
   */
  checkSidebar(): void {
    const query = this.queryParamsService.get('sm');
    const keys = Object.keys(query);
    if (!keys.length) {
      return;
    }

    /* get only last key */
    const lastKey = keys.slice(-1)[0];
    const componentName = lastKey.replace('sm_', '');
    let component;

    switch (componentName) {
      case 'dspView': {
        component = ConnectedDspViewComponent;
        break;
      }
      case 'publisherView': {
        component = PublisherViewComponent;
        break;
      }
      case 'spotView': {
        component = SpotViewComponent;
        break;
      }
      case 'tariffView': {
        component = TariffViewComponent;
        break;
      }
      case 'profileView': {
        component = UserProfileViewComponent;
        break;
      }
      case 'userView': {
        component = UserViewComponent;
        break;
      }
    }

    if (!component) {
      return;
    }

    this.sidebarModal.open(component, {
      data: {
        id: query[lastKey]
      }
    });
  }

  /**
   * Hide sidebar id is reporter or if user has no confirm survey form
   * @return {boolean}
   */
  get isShowSidebar(): boolean {
    return !this.layoutService.isNeedContactQuiz;
  }

  /**
   * Open order payout form
   */
  orderPayout(): void {
    this.modal.open(PayoutOrderFormComponent, {
      width: '640px',
      data: this.profile
    });
  }
}
