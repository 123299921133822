import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuiModule } from '../../gui/gui.module';
import { DynamicGridModule } from '../../dynamic-modules/dynamic-grid/dynamic-grid.module';
import { DynamicFilterModule } from '../../dynamic-modules/dynamic-filter/dynamic-filter.module';

const ENTRY_MODULES = [GuiModule, DynamicGridModule, DynamicFilterModule];

@NgModule({
  imports: [CommonModule, ...ENTRY_MODULES]
})
export class EventsLogModule {}
