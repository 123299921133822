import { inject } from '@angular/core';
import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AlertsComponent } from '../../ui/components/alerts/alerts.component';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import * as Sentry from '@sentry/angular';
import { APP_ROUTE } from '../routes';

export const httpErrorsInterceptor: HttpInterceptorFn = (request, next) => {
  const alerts = inject(AlertsComponent);
  const router = inject(Router);
  const authService = inject(AuthService);

  return next(request).pipe(
    retry(1),
    catchError((error: HttpErrorResponse) => {
      let errorMessage = error.error?.message ? error.error.message : 'System error';

      if (error.status === 418) {
        errorMessage = 'Max period CSV export 35 days';
      }

      if (error.status === 401) {
        authService.logout();
        router.navigate([APP_ROUTE.auth], {
          queryParams: { return: router.url }
        });
      }

      if (error.error.status === 'WARNING') {
        alerts.warning(errorMessage, 3000);
      } else {
        alerts.error(errorMessage, 3000);
      }

      let errorMessageForSentry = error.error?.message ? error.error.message : error.message;
      Sentry.captureMessage(errorMessageForSentry, scope => scope.setLevel('error'));
      return throwError(errorMessage);
    })
  );
};
