import { Component, DestroyRef, HostBinding, inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertsComponent } from '../../../ui/components/alerts/alerts.component';
import { Dicts, ProfileDetail } from '../../../core/interfaces';
import { SspService } from '../../../core/services/ssp.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { IsMobileService } from '../../../core/services/is-mobile.service';
import { TranslocoService } from '@jsverse/transloco';
import { APP_ROUTE } from '../../../core/routes';
import { ProfileService } from '../../../core/services/profile.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-contact-quiz',
  templateUrl: './contact-quiz.component.html',
  styleUrls: ['./contact-quiz.component.scss']
})
export class ContactQuizComponent implements OnInit {
  @HostBinding('class') hostClass = this.checkUserAgent.isMobile ? 'mobile' : 'desktop';
  @ViewChild('sidenav') sidenav: MatSidenav;
  public today: number = Date.now();
  public showSubmenu = false;
  public dicts = {
    feedback: {
      data: [
        {
          id: 'searchSystems',
          value: 'searchSystems'
        },
        {
          id: 'forumsMessengers',
          value: 'forumsMessengers'
        },
        {
          id: 'friends',
          value: 'friends'
        },
        {
          id: 'ourManager',
          value: 'ourManager'
        },
        {
          id: 'socialNetworks',
          value: 'socialNetworks'
        },
        {
          id: 'other',
          value: 'other'
        }
      ]
    },
    socials: {
      data: [
        {
          id: 'facebook',
          value: 'facebook.com'
        },
        {
          id: 'linkedin',
          value: 'linkedin.com'
        },
        {
          id: 'twitter',
          value: 'twitter.com'
        }
      ]
    }
  } as Dicts;
  public form: UntypedFormGroup;
  public show: number;
  public feedbackPlaceholder = '';
  public isLoading = false;
  public stage = 1;
  public profile: ProfileDetail;

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private alerts: AlertsComponent,
    private sspService: SspService,
    private router: Router,
    public checkUserAgent: IsMobileService,
    public transloco: TranslocoService,
    public profileService: ProfileService
  ) {
    this.profileService.profile$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(profile => !!profile)
      )
      .subscribe({
        next: profile => (this.profile = profile)
      });
  }

  ngOnInit(): void {
    this.loadMessengersDict();
    this.createForm();
  }

  /**
   * Get 'feedback' form control
   */
  get type(): UntypedFormGroup {
    return this.form.get('feedback.type') as UntypedFormGroup;
  }

  /**
   * Get 'feedback info' form control
   */
  get feedbackInfo(): UntypedFormGroup {
    return this.form.get('feedback.info') as UntypedFormGroup;
  }

  /**
   * Get 'feedback type' form control
   */
  get feedbackType(): UntypedFormGroup {
    return this.form.get('feedback.type') as UntypedFormGroup;
  }

  /**
   * Get 'messenger type' form control
   */
  get messengerType(): UntypedFormGroup {
    return this.form.get('messengers.type') as UntypedFormGroup;
  }

  /**
   * Get 'messenger type' form control
   */
  get messengerInfo(): UntypedFormGroup {
    return this.form.get('messengers.info') as UntypedFormGroup;
  }

  createForm() {
    this.form = this.fb.group({
      messengers: this.fb.group({
        type: ['', Validators.required],
        info: ['', [Validators.required, Validators.minLength(3)]]
      }),
      feedback: this.fb.group({
        type: ['', Validators.required],
        info: ['', [Validators.required, Validators.minLength(2)]]
      })
    });
  }

  /**
   * Load messengers dictionary
   */
  loadMessengersDict(): void {
    this.isLoading = true;

    const request = {
      limit: 200,
      page: 1
    };

    this.sspService
      .getMessengers(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoading = false;
          this.dicts.messengers = result;
        },
        error: () => (this.isLoading = false)
      });
  }

  closeSidenav() {
    this.sidenav.close();
  }

  toggleSubmenu() {
    this.showSubmenu = !this.showSubmenu;
  }

  /**
   * Show/Hide advanced fields
   *
   * @param e - selected value
   */
  onChangeVariant(e): void {
    if (this.checkUserAgent.isMobile) {
      e = e.target.value;
    }

    const validators = [Validators.required, Validators.minLength(2)];

    switch (e) {
      case 'socialNetworks':
        this.feedbackInfo.reset();
        this.feedbackInfo.enable();
        this.feedbackInfo.setValidators(validators);
        this.show = 1;
        break;
      case 'forumsMessengers':
        this.feedbackPlaceholder = 'insertForumsMessengers';
        this.show = 2;
        this.feedbackInfo.reset();
        this.feedbackInfo.enable();
        this.feedbackInfo.setValidators(validators);
        break;
      case 'ourManager':
        this.feedbackPlaceholder = 'insertOurManager';
        this.show = 2;
        this.feedbackInfo.reset();
        this.feedbackInfo.enable();
        this.feedbackInfo.setValidators(validators);
        break;
      case 'other':
        this.feedbackPlaceholder = '';
        this.feedbackInfo.reset();
        this.feedbackInfo.enable();
        this.feedbackInfo.clearValidators();
        this.show = 2;
        break;
      default:
        this.feedbackInfo.disable();
        this.show = 0;
        break;
    }

    this.feedbackInfo.updateValueAndValidity();
  }

  /**
   * Submit form
   */
  submit(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.profileService
      .setFeedback(this.form.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoading = false;
          if (result.status === 'OK') {
            const profileDetails = {
              ...this.profile
            };

            profileDetails.profile.feedback = true;

            this.profileService.setProfile(profileDetails);

            if (this.checkUserAgent.isMobile) {
              this.stage = 2;
            }
          }
        },
        complete: () => this.alerts.success(this.transloco.translate('thankYou'), 3000),
        error: () => (this.isLoading = false)
      });
  }

  goTo(): void {
    this.router.navigate([APP_ROUTE.spots]);
  }
}
