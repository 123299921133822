import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, TitleStrategy } from '@angular/router';
import { routes } from './routes';
import { GuiModule } from './gui/gui.module';
import { AuthModule } from './auth/auth.module';
import { LayoutModule } from './layout/layout.module';
import { StatisticModule } from './modules/statistic/statistic.module';
import { UserProfileModule } from './modules/user-profile/user-profile.module';
import { UsersModule } from './modules/users/users.module';
import { PayoutsModule } from './modules/payouts/payouts.module';
import { EventsLogModule } from './modules/events-log/events-log.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { OpenReplayHttpConfigInterceptor } from './core/interceptor/open-replay-http-config.interceptor';
import { IsMobileService } from './core/services/is-mobile.service';
import { SentryService } from './core/services/sentry.service';
import { httpErrorsInterceptor } from './core/interceptor/http-errors.interceptor';
import * as Sentry from '@sentry/angular';
import { provideTransloco } from '@jsverse/transloco';
import { environment } from '../environments/environment';
import { provideTranslocoPersistLang } from '@jsverse/transloco-persist-lang';
import { TranslocoHttpLoader } from './transloco/transloco-loader';
import { AppPagesTitleService } from './core/services/app-pages-title.service';
import { tokenInterceptor } from './core/interceptor/token.interceptor';
import { TariffsModule } from './modules/tariffs/tariffs.module';
import { SpotsModule } from './modules/spots/spots.module';
import { PublishersModule } from './modules/publishers/publishers.module';
import { ConnectedDspsModule } from './modules/connected-dsps/connected-dsps.module';

export function getLangFn({ cachedLang }) {
  return cachedLang;
}

const ENTRY_MODULES = [
  GuiModule,
  AuthModule,
  LayoutModule,
  StatisticModule,
  UserProfileModule,
  UsersModule,
  PayoutsModule,
  EventsLogModule,
  TariffsModule,
  PublishersModule,
  ConnectedDspsModule,
  SpotsModule
];

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideAnimations(),
    provideAnimationsAsync(),
    provideRouter(routes),
    provideHttpClient(withInterceptors([tokenInterceptor, httpErrorsInterceptor])),
    importProvidersFrom(...ENTRY_MODULES),
    provideTransloco({
      config: {
        availableLangs: ['en', 'ja', 'zh-cn', 'zh-tw', 'ko', 'es'],
        defaultLang: 'en',
        fallbackLang: 'en',
        reRenderOnLangChange: true,
        prodMode: environment.production,
        failedRetries: 1,
        missingHandler: {
          logMissingKey: false
        }
      },
      loader: TranslocoHttpLoader
    }),
    provideTranslocoPersistLang({
      getLangFn,
      storage: {
        useValue: localStorage
      }
    }),
    {
      provide: 'googleTagManagerId',
      useValue: 'GTM-TPRTK9L'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OpenReplayHttpConfigInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (isMobile: IsMobileService, sentry: SentryService) => () => {
        isMobile.initCheck();
        sentry.initSentry();
      },
      deps: [IsMobileService, SentryService],
      multi: true
    },
    {
      provide: TitleStrategy,
      useClass: AppPagesTitleService
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    }
  ]
};
