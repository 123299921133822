import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Config } from '../core/interfaces';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);
  private config: Config = environment;

  getTranslation(lang: string) {
    return this.http.get<Translation>(`${this.config.translationApi}/get?locale=${lang}`);
  }
}
