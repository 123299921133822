import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdxadModalRef } from '../../../../ui/modules/modal/modal-ref';
import { takeUntil } from 'rxjs/operators';
import { AlertsComponent } from '../../../../ui/components/alerts/alerts.component';
import { ProfileService } from '../../../../core/services/profile.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-password-form',
  templateUrl: './user-change-password-form.component.html',
  styleUrls: ['./user-change-password-form.component.scss']
})
export class UserChangePasswordFormComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = false;
  public isLoadingSubmit = false;
  public showPassword = true;

  private destroyRef = inject(DestroyRef);

  constructor(
    private modalRef: AdxadModalRef,
    private fb: UntypedFormBuilder,
    private profileService: ProfileService,
    private alerts: AlertsComponent
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  /**
   * Create change password form
   */
  createForm(): void {
    this.form = this.fb.group({
      oldPassword: ['', [Validators.required, Validators.minLength(6)]],
      newPassword: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  /**
   * Close modal
   * if submit == true, grid will reload
   *
   * @param {boolean} submit
   */
  closeModal(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }

  submit() {
    if (!this.form.valid || this.isLoadingSubmit) {
      return;
    }
    this.isLoadingSubmit = true;
    const data = this.form.value;

    this.profileService
      .changePassword(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: response => {
          this.isLoadingSubmit = false;

          if (response.status === 'OK') {
            this.alerts.success('Password changed', 3000);
            this.closeModal(true);
          }
        },
        error: () => (this.isLoadingSubmit = false)
      });
  }
}
