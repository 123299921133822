import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { GuiModule } from '../../gui/gui.module';
import { PublisherFormComponent } from './components/publisher-form/publisher-form.component';
import { PublisherViewComponent } from './components/publisher-view/publisher-view.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ManagerFormComponent } from './components/manager-form/manager-form.component';
import { DynamicFilterModule } from '../../dynamic-modules/dynamic-filter/dynamic-filter.module';
import { PublisherBlockingConfirmComponent } from './components/publisher-blocking-confirm/publisher-blocking-confirm.component';

const ENTRY_COMPONENTS = [PublisherFormComponent, PublisherViewComponent, ManagerFormComponent, PublisherBlockingConfirmComponent];

@NgModule({
  declarations: [...ENTRY_COMPONENTS],
  imports: [CommonModule, TranslocoModule, GuiModule, ReactiveFormsModule, DynamicFilterModule]
})
export class PublishersModule {}
