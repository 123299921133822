import { NgModule } from '@angular/core';
import { GuiModule } from '../../gui/gui.module';
import { DynamicFilterModule } from '../../dynamic-modules/dynamic-filter/dynamic-filter.module';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { PayoutOrderFormComponent } from './components/payout-order-form/payout-order-form.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PayoutOrderFormComponent],
  imports: [CommonModule, GuiModule, DynamicFilterModule, NgOptimizedImage, TranslocoModule, ReactiveFormsModule]
})
export class PayoutsModule {}
