import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { APP_ROUTE } from '../routes';
import { AuthService } from '../../auth/auth.service';
import { AlertsComponent } from '../../ui/components/alerts/alerts.component';
import { AuthToken } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class MainGuard {
  static createMask() {
    let mask = document.getElementsByClassName('reload-token')[0];
    if (mask) {
      return;
    }
    mask = document.createElement('div');
    mask.className = 'reload-token';

    const loader = document.createElement('div');
    loader.className = 'reload-token__loader';
    mask.appendChild(loader);

    document.body.appendChild(mask);
  }

  static removeMask() {
    const mask = document.getElementsByClassName('reload-token')[0];
    if (!mask) {
      return;
    }
    mask.remove();
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private alerts: AlertsComponent
  ) {}

  private goToAuth(state: RouterStateSnapshot) {
    this.router.navigate([APP_ROUTE.auth], {
      queryParams: { return: state.url }
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let canActive = true;
    if (!this.authService.hasToken) {
      canActive = false;
      this.goToAuth(state);
    } else if (this.authService.isTokenExpired) {
      canActive = false;

      MainGuard.createMask();
      this.authService.refreshToken().subscribe({
        next: (token: AuthToken) => {
          this.authService.setToken(token);
          MainGuard.removeMask();
          canActive = true;
          this.router.navigateByUrl(state.url);
        },
        error: () => {
          this.alerts.warning('Session timed out. Please log in again', 8000);
          MainGuard.removeMask();
          canActive = false;
          this.authService.setToken(null);
          this.goToAuth(state);
        }
      });
    }

    return canActive;
  }
}
